<template>
  <p>Redirecting...</p>
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    if (this.$route.query.token) {
      this.verifyToken();
    }
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
  },
  methods: {
    async verifyToken() {
      await this.$store
        .dispatch("auth/oAuthLogin", {
          token: this.$route.query.token,
        })
        .then(() => {
          if (!this.user?.current_organization?.code) {
            this.$router.push({
              path: 'not_found',
            });
          }
          this.$router.push({
            name: "DashboardJump",
            params: { org_code: this.user.current_organization?.code },
          });
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status && error.response.status === 422) {
            this.validationErrors = error.response.data.message;
          }
          if (error.response.data?.status === 'failed') {
            this.$swal({
              title: "登入失敗",
              type: "errorZ",
              text: error.response.data.message,
              confirmButtonText: "返回",
              confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
            }).then(async () => {
              if (!error.response.data.data.redirect_uri && this.user.current_organization?.code) {
                this.$router.push({ name: 'AuthLogin', params: { org_code: this.user.current_organization?.code }})
              } else {
                window.location.href = error.response.data.data.redirect_uri;
              }

            });
          }
        });
    }
  }
}
</script>
